import { template as template_8a05cb4054a7424ab43a1cd503b73e9c } from "@ember/template-compiler";
const FKText = template_8a05cb4054a7424ab43a1cd503b73e9c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
