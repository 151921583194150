import { template as template_b193e56b80514e71b857154887cbe6b3 } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_b193e56b80514e71b857154887cbe6b3(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
