import { template as template_6d1e79ca4d9b4b4d8a320a67cf64ce72 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_6d1e79ca4d9b4b4d8a320a67cf64ce72(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
